import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    console.log("Hello, from reset Form!", this.element)
  }

  reset() {
    console.log('Reset Form Controller Reset!')
    this.element.reset()
    this.buttonTarget.disabled = false
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hidden_fields" ]

  toggle() {
    this.hidden_fieldsTarget.disabled = true
    // let y = window.scrollY
    if (this.hidden_fieldsTarget.classList.contains("hidden")) {
      this.hidden_fieldsTarget.classList.remove("hidden")
    } else {
      this.hidden_fieldsTarget.classList.add("hidden")
    }
    this.hidden_fieldsTarget.disabled = false
    // window.scroll(0,y)
  }
}

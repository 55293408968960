import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "today_list", "today_btn", 
    "overdue_list", "overdue_btn", 
    "completed_list", "completed_btn", 
    "someday_list", "someday_btn", 
    "tomorrow_list", "tomorrow_btn", 
    "future_list", "future_btn", 
    "log_list", "log_btn" 
  ]

  only_show_today() {
    this.today_btnTarget.disabled = true
    if (this.any_other_btn_is_hidden_except("today")) {
      if (this.today_listTarget.classList.contains("hidden")) {
        this.show_today(true)
        this.show_overdue(false)
        this.show_tomorrow(false)
        this.show_someday(false)
      } else {
        this.show_today(true)
        this.show_overdue(true)
        this.show_tomorrow(true)
        this.show_someday(true)
      }
    } else {
      this.show_today(true)
      this.show_overdue(false)
      this.show_tomorrow(false)
      this.show_someday(false)
    }
    this.today_btnTarget.disabled = false
  }

  only_show_overdue() {
    this.overdue_btnTarget.disabled = true
    if (this.any_other_btn_is_hidden_except("overdue")) {
      if (this.overdue_listTarget.classList.contains("hidden")) {
        this.show_today(false)
        this.show_overdue(true)
        this.show_tomorrow(false)
        this.show_someday(false)
      } else {
        this.show_today(true)
        this.show_overdue(true)
        this.show_tomorrow(true)
        this.show_someday(true)
      }
    } else {
      this.show_today(false)
      this.show_overdue(true)
      this.show_tomorrow(false)
      this.show_someday(false)
    }
    this.overdue_btnTarget.disabled = false
  }

  only_show_tomorrow() {
    this.tomorrow_btnTarget.disabled = true
    if (this.any_other_btn_is_hidden_except("tomorrow")) {
      if (this.tomorrow_listTarget.classList.contains("hidden")) {
        this.show_today(false)
        this.show_overdue(false)
        this.show_tomorrow(true)
        this.show_someday(false)
      } else {
        this.show_today(true)
        this.show_overdue(true)
        this.show_tomorrow(true)
        this.show_someday(true)
      }
    } else {
      this.show_today(false)
      this.show_overdue(false)
      this.show_tomorrow(true)
      this.show_someday(false)
    }
    this.tomorrow_btnTarget.disabled = false
  }

  only_show_someday() {
    this.someday_btnTarget.disabled = true
    if (this.any_other_btn_is_hidden_except("someday")) {
      if (this.someday_listTarget.classList.contains("hidden")) {
        this.show_today(false)
        this.show_overdue(false)
        this.show_tomorrow(false)
        this.show_someday(true)
      } else {
        this.show_today(true)
        this.show_overdue(true)
        this.show_tomorrow(true)
        this.show_someday(true)
      }
    } else {
      this.show_today(false)
      this.show_overdue(false)
      this.show_tomorrow(false)
      this.show_someday(true)
    }
    this.someday_btnTarget.disabled = false
  }


  show_today(show) {
    if (show) {
      this.today_listTarget.classList.remove("hidden")
      this.today_btnTarget.classList.remove("bullet-filter-btn-inactive")
      this.today_btnTarget.classList.add("bullet-filter-btn-active")
    } else {
      this.today_listTarget.classList.add("hidden")
      this.today_btnTarget.classList.add("bullet-filter-btn-inactive")
      this.today_btnTarget.classList.remove("bullet-filter-btn-active")
    }
  }

  show_overdue(show) {
    if (show) {
      this.overdue_listTarget.classList.remove("hidden")
      this.overdue_btnTarget.classList.remove("bullet-filter-btn-inactive")
      this.overdue_btnTarget.classList.add("bullet-filter-btn-active")
    } else {
      this.overdue_listTarget.classList.add("hidden")
      this.overdue_btnTarget.classList.add("bullet-filter-btn-inactive")
      this.overdue_btnTarget.classList.remove("bullet-filter-btn-active")
    }
  }

  show_tomorrow(show) {
    if (show) {
      this.tomorrow_listTarget.classList.remove("hidden")
      this.tomorrow_btnTarget.classList.remove("bullet-filter-btn-inactive")
      this.tomorrow_btnTarget.classList.add("bullet-filter-btn-active")
    } else {
      this.tomorrow_listTarget.classList.add("hidden")
      this.tomorrow_btnTarget.classList.add("bullet-filter-btn-inactive")
      this.tomorrow_btnTarget.classList.remove("bullet-filter-btn-active")
    }
  }

  show_someday(show) {
    if (show) {
      this.someday_listTarget.classList.remove("hidden")
      this.someday_btnTarget.classList.remove("bullet-filter-btn-inactive")
      this.someday_btnTarget.classList.add("bullet-filter-btn-active")
    } else {
      this.someday_listTarget.classList.add("hidden")
      this.someday_btnTarget.classList.add("bullet-filter-btn-inactive")
      this.someday_btnTarget.classList.remove("bullet-filter-btn-active")
    }
  }

  any_other_btn_is_hidden_except(element) {
    if (this.today_listTarget.classList.contains("hidden")) {
      if (this.today_listTarget != element) {
        return true
      }
    }
    if (this.tomorrow_listTarget.classList.contains("hidden")) {
      return true
    }
    false
  }
}

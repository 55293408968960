import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
require("./../stylesheets/application.scss")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "channels"
import "bootstrap"
import "@hotwired/turbo"
import "src/analytics"

const application = Application.start()
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

document.addEventListener("turbo:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})

Rails.start()
